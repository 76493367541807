import { FlexBox } from '@codecademy/gamut';
import { system, variant } from '@codecademy/gamut-styles';
import { StyleProps } from '@codecademy/variance';
import styled from '@emotion/styled';

const iconContainerStates = system.states({
  dim: { bg: 'gray-600', borderColor: 'gray-600', color: 'gray-900' },
  noBorder: { borderColor: 'transparent' },
});

const iconContainerVariants = variant({
  base: {
    borderRadius: 'full',
    border: 1,
    borderColor: 'black',
  },
  defaultVariant: 'default',
  variants: {
    default: {},
    locked: {},
    unlocked: {},
    complete: { bg: 'yellow', color: 'black' },
    failed: { bg: 'red', color: 'white' },
    active: {
      bg: 'white',
      borderColor: 'black',
      color: 'black',
    },
  },
});

type IconContainerProps = StyleProps<typeof iconContainerStates> &
  StyleProps<typeof iconContainerVariants>;
export const IconContainer = styled(FlexBox)<IconContainerProps>(
  iconContainerVariants,
  iconContainerStates
);
