import {
  SkillsByIdsQuery,
  SkillsQuery,
} from '@mono/data/codegen/client/graphql-gateway/graphql';

import { SkillTag, SkillTagWithNormalizedExperience } from './types';

export type SkillDashboard = {
  id: string;
  title: string;
  experience: number;
  slug: string;
  classification: string;
};

export const isSkillTag = (
  skill: SkillTagWithNormalizedExperience
): skill is SkillTag => 'title' in skill;

export const getHighestExperience = (skills: SkillTag[]) => {
  const highestExperience = skills.reduce(
    (acc, skill) => (skill.experience > acc ? skill.experience : acc),
    0
  );

  return highestExperience;
};

export const orderSkillsByExperience = <T extends SkillTag>(
  skills: T[] | undefined
): T[] | undefined => {
  try {
    if (!skills) return undefined;

    const sortedSkills = [...skills].sort((a, b) => {
      if (a.experience === b.experience) {
        return a.title.localeCompare(b.title);
      }

      return b.experience - a.experience;
    });

    return sortedSkills;
  } catch {
    return [];
  }
};

export const orderSkillsByUpdatedAt = <T extends SkillTag>(
  skills: T[] | undefined
): T[] => {
  try {
    if (!skills) return [];

    const sortedSkills = skills.sort((a, b) => {
      if (a.lastUpdatedAt && b.lastUpdatedAt) {
        const dateA = new Date(a.lastUpdatedAt);
        const dateB = new Date(b.lastUpdatedAt);

        if (dateA.getTime() === dateB.getTime()) {
          return a.title.localeCompare(b.title);
        }

        return dateB.getTime() - dateA.getTime();
      }
      return 0;
    });

    return sortedSkills;
  } catch {
    return [];
  }
};

export const calculatePercent = (value: number, total: number) => {
  return (value / total) * 100;
};

export const calculateExperienceBarWidth = ({
  experience,
  maxExperience,
}: {
  experience: number;
  maxExperience: number;
}) => {
  return Math.floor(calculatePercent(experience, maxExperience));
};

export const numDigits = (num: number) => {
  return Math.max(Math.floor(Math.log10(Math.abs(num))), 0) + 1;
};

// Calculate tick spacing and nice minimum and maximum data points on the axis.

export const calculateTicksAndRange = (
  maxTicks: number,
  minPoint: number,
  maxPoint: number
): [number, number, number] => {
  const range = niceNum(maxPoint - minPoint, false);
  const tickSpacing = niceNum(range / (maxTicks - 1), true);
  const niceMin = Math.floor(minPoint / tickSpacing) * tickSpacing;
  const niceMax = Math.ceil(maxPoint / tickSpacing) * tickSpacing;
  const tickCount = range / tickSpacing;
  return [tickCount, niceMin, niceMax];
};

/**
 * Returns a "nice" number approximately equal to range
 * Rounds the number if round = true
 * Takes the ceiling if round = false.
 * A nice number is a simple decimal number, for example if a number is 1234, a nice number would be 1000 or 2000.
 */
export const niceNum = (range: number, roundDown: boolean): number => {
  const exponent = Math.floor(Math.log10(range));
  const fraction = range / 10 ** exponent;

  let niceFraction: number;

  if (roundDown) {
    if (fraction < 1.5) niceFraction = 1;
    else if (fraction < 3) niceFraction = 2;
    else if (fraction < 7) niceFraction = 5;
    else niceFraction = 10;
  } else if (fraction <= 1) niceFraction = 1;
  else if (fraction <= 2) niceFraction = 2;
  else if (fraction <= 5) niceFraction = 5;
  else niceFraction = 10;

  return niceFraction * 10 ** exponent;
};

export const getPercentDiff = (v1: number, v2: number) => {
  return (Math.abs(v1 - v2) / ((v1 + v2) / 2)) * 100;
};

export const formatNumberUS = (num: number) =>
  Intl.NumberFormat('en').format(num);

export const formatNumberUSCompact = (num: number) =>
  Intl.NumberFormat('en', {
    notation: 'compact',
    compactDisplay: 'short',
  }).format(num);

export const calcDailyGainedExperience = (
  skillTags: SkillTag[] | undefined
) => {
  if (!skillTags) return 0;

  return skillTags.reduce((acc, skill) => {
    return (skill.latestExperience || 0) + acc;
  }, 0);
};

export const normalizeLESkillsWithExperience = (
  skills: SkillsByIdsQuery['skills'] | undefined
) => {
  if (!skills) return [];

  return skills.reduce((acc, skill) => {
    if (skill.practiceTotalExperience !== undefined) {
      return [
        ...acc,
        {
          ...skill,
          experience:
            skill.contentItemTotalExperience + skill.practiceTotalExperience,
        },
      ];
    }
    return [
      ...acc,
      {
        ...skill,
        experience: skill.contentItemTotalExperience,
      },
    ];
  }, []);
};

export const normalizeDashboardSkillsWithExperience = (
  skills: SkillsQuery['skillTags'] | undefined
): SkillTagWithNormalizedExperience[] | undefined => {
  if (!skills) return undefined;

  return skills.reduce((acc, skill) => {
    if (
      skill.practiceLatestExperience !== undefined &&
      skill.practiceTotalExperience !== undefined &&
      skill.practiceLastUpdatedAt !== undefined
    ) {
      const lastUpdatedAt =
        new Date(skill.contentItemLastUpdatedAt || '') >
        new Date(skill.practiceLastUpdatedAt || '')
          ? skill.contentItemLastUpdatedAt
          : skill.practiceLastUpdatedAt;
      return [
        ...acc,
        {
          ...skill,
          experience:
            skill.contentItemTotalExperience + skill.practiceTotalExperience,
          latestExperience:
            skill.contentItemLatestExperience + skill.practiceLatestExperience,
          lastUpdatedAt,
        },
      ];
    }
    return [
      ...acc,
      {
        ...skill,
        experience: skill.contentItemTotalExperience,
        latestExperience: skill.contentItemLatestExperience,
        lastUpdatedAt: skill.contentItemLastUpdatedAt,
      },
    ];
  }, []);
};
