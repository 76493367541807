import { Text } from '@codecademy/gamut';
import {
  ArticleIcon,
  BookClosedBookmarkIcon,
  CheckIcon,
  ChecklistIcon,
  CloseIcon,
  FileSearchIcon,
  GamutIconProps,
  LessonIcon,
  LockHeavyIcon,
  MiniStarIcon,
  PinIcon,
  PlayIcon,
  PortfolioProjectIcon,
  ProjectsIcon,
  QuizIcon,
  UnlockHeavyIcon,
} from '@codecademy/gamut-icons';

import { ContentItemIconProps } from './ContentItemIcon';

const iconMap: Record<string, typeof ArticleIcon> = {
  article: ArticleIcon,
  informational: ArticleIcon,
  lesson: LessonIcon,
  project: ProjectsIcon,
  quiz: QuizIcon,
  resource: ArticleIcon,
  video: PlayIcon,
  seminar: ArticleIcon,
  external_resource: PinIcon,
  project_component: ProjectsIcon,
  reviewable_project: ProjectsIcon,
  book: BookClosedBookmarkIcon,
  documentation: FileSearchIcon,
  general_resource: PinIcon,
  tutorial: ChecklistIcon,
  kanban_project: PortfolioProjectIcon,
};

export const Icon: React.FC<
  React.PropsWithChildren<ContentItemIconProps & GamutIconProps>
> = ({
  active,
  complete,
  contentItemId,
  failed,
  perfect,
  isProLocked,
  isUnlocked,
  shouldHaveScreenreaderStates,
  resourceType,
  type,
  ...props
}) => {
  const IconType = iconMap[resourceType || type] || PinIcon;
  if (perfect) return <MiniStarIcon {...props} />;
  if (complete) return <CheckIcon {...props} />;
  if (failed) return <CloseIcon {...props} />;
  if (isUnlocked) return <UnlockHeavyIcon {...props} />;
  if (isProLocked) return <LockHeavyIcon {...props} />;

  return (
    <>
      {shouldHaveScreenreaderStates && (
        <>
          {complete && <Text screenreader>Complete</Text>}
          {active && <Text screenreader>Current</Text>}
        </>
      )}
      <IconType {...props} />
    </>
  );
};
