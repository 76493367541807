import { ContentItemType } from '@mono/brand';
import { ExternalResourceResource } from '@mono/data/codegen/client/graphql-gateway/graphql';

const contentItemTypes = {
  typeDisplayNames: {
    project: 'project',
    quiz: 'quiz',
    resource: 'resource',
    lesson: 'lesson',
    article: 'article',
    informational: 'informational',
    video: 'video',
    book: 'reading',
    documentation: 'docs',
    tutorial: 'tutorial',
    general_resource: 'resource',
    external_resource: 'external resource',
    kanban_project: 'portfolio',
  },
  longTypeDisplayNames: {
    project: 'project',
    quiz: 'quiz',
    resource: 'resource',
    lesson: 'lesson',
    article: 'article',
    informational: 'informational',
    video: 'video',
    book: 'recommended reading',
    documentation: 'documentation',
    tutorial: 'tutorial',
    general_resource: 'resource',
    external_resource: 'external resource',
    kanban_project: 'portfolio project',
  },
};

export interface ContentTypeDisplayOptions {
  longForm?: boolean;
}
export type ContentTypeDisplayNames = Record<string, string | undefined>;

export const getTypeDisplayName = (
  type: ContentItemType,
  resourceType?: ExternalResourceResource | null,
  options: ContentTypeDisplayOptions = {}
) => {
  const { longForm } = options;
  const displayNameStrings: ContentTypeDisplayNames = longForm
    ? contentItemTypes.longTypeDisplayNames
    : contentItemTypes.typeDisplayNames;

  return displayNameStrings[resourceType || type] || type;
};

export const hasQuizAccess = (
  contentItemType: ContentItemType,
  contentItemId: string | undefined,
  entitlements?: string[] | null,
  freeQuizzesTaken?: string[] | null
) => {
  const isQuiz = contentItemType === 'quiz';
  const canRetakeQuiz =
    contentItemId && freeQuizzesTaken?.includes(contentItemId);
  const hasQuizEntitlement = entitlements?.includes('quizzes');

  return Boolean(isQuiz && (hasQuizEntitlement || canRetakeQuiz));
};

export const hasProjectAccess = (
  contentItemType: ContentItemType,
  contentItemId: string | undefined,
  entitlements?: string[] | null,
  freeProjectsTaken?: string[] | null
) => {
  const isProject = contentItemType === 'project';
  const canWorkOnProject =
    contentItemId && freeProjectsTaken?.includes(contentItemId);
  const hasProjectEntitlement = entitlements?.includes('practice_projects');

  return Boolean(isProject && (hasProjectEntitlement || canWorkOnProject));
};

export const getIsUnlockedProjectOrQuiz = (
  contentItemType: ContentItemType | undefined,
  contentItemId: string | undefined,
  isFreeUser: boolean,
  entitlements?: string[] | null,
  freeProjectsTaken?: string[] | null,
  freeQuizzesTaken?: string[] | null
) => {
  const isProjectUnlocked = hasProjectAccess(
    contentItemType || 'project',
    contentItemId,
    entitlements,
    freeProjectsTaken
  );

  const isQuizUnlocked = hasQuizAccess(
    contentItemType || 'quiz',
    contentItemId,
    entitlements,
    freeQuizzesTaken
  );

  return isFreeUser && (isProjectUnlocked || isQuizUnlocked);
};
